import {
  Flex,
  Button,
  Stack,
  useToast,
  Box,
  Image,
  Text,
  keyframes,
} from '@chakra-ui/react';

import { Input } from '../Form/Input';

import { signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { translations } from './translations';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/shadcn-ui/components/ui/carousel';
import useEmblaCarousel from 'embla-carousel-react';

export function Login() {
  const [values, setValues] = useState({ email: '', password: '' });
  const [loading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState('/logo3.svg');

  const router = useRouter();
  const toast = useToast();

  const handleInput = (field: string, value: string) => {
    setValues((s) => ({ ...s, [field]: value }));
  };

  const eplusLogo =
    'https://www.agenciaeplus.com.br/wp-content/themes/eplus/images/agencia-eplus-n-logo.png';

  useEffect(() => {
    const whitelabelUrl = 'ecommercedashboard.com.br';

    if (window.location.host === whitelabelUrl) {
      setLogoUrl(eplusLogo);
    }
  }, []);

  const t = translations(router.locale);

  const ORBIT_DIAMETER = 564;

  const spinRight = keyframes`
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  `;

  const spinLeft = keyframes`
  100% {
      -webkit-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  `;

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

  useEffect(() => {
    if (!emblaApi) return; // Ensure emblaApi is available

    const interval = setInterval(() => {
      emblaApi.scrollNext(); // Scroll to the next slide
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [emblaApi]);

  return (
    <Flex h="100vh" w="100vw" flexDir="row">
      <Flex
        display={logoUrl === eplusLogo ? 'none' : 'flex'}
        h="100%"
        w={{
          base: 0,
          md: '55%',
        }}
        bgGradient={`linear(to-tr, brand.blue, brand.purple)`}
        flexDir="column"
        alignItems="center"
        justify="center"
        visibility={{
          base: 'hidden',
          md: 'visible',
        }}
      >
        <Flex
          backgroundImage={'/stars.svg'}
          backgroundRepeat="repeat"
          justifyContent="center"
          alignItems="center"
          w={'full'}
          h={'full'}
        >
          <Box
            id="circle-orbit-container"
            borderRadius={'100%'}
            height={{
              md: `${ORBIT_DIAMETER}px`,
              base: `${ORBIT_DIAMETER / 2}px`,
            }}
            width={{
              md: `${ORBIT_DIAMETER}px`,
              base: `${ORBIT_DIAMETER / 2}px`,
            }}
            transform={'skew(5deg)'}
          >
            <Box
              id="inner-orbit"
              position="absolute"
              top={{
                md: `${ORBIT_DIAMETER / 4}px`,
                base: `${ORBIT_DIAMETER / 8}px`,
              }}
              left={{
                md: `${ORBIT_DIAMETER / 4}`,
                base: `${ORBIT_DIAMETER / 8}px`,
              }}
              height={{
                md: `${ORBIT_DIAMETER / 2}px`,
                base: `${ORBIT_DIAMETER / 4}px`,
              }}
              width={{
                md: `${ORBIT_DIAMETER / 2}px`,
                base: `${ORBIT_DIAMETER / 4}px`,
              }}
              border="2px #fff dashed"
              borderRadius="100%"
              animation={`${spinRight} 15s linear infinite`}
            >
              <Box
                position="absolute"
                top={{ md: '-30px', base: '-15px' }}
                left={{ md: '100px', base: '50px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 15s linear infinite`}
                bg="gray.100"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="vtex-circle-logo"
                  src="/tiktok-circle-logo.svg"
                />
              </Box>

              <Box
                position="absolute"
                top={{ md: '160px', base: '80px' }}
                left={{ md: '230px', base: '115px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 15s linear infinite`}
                bg="gray.100"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="tray-logo"
                  src="/google-circle-logo.svg"
                />
              </Box>

              <Box
                position="absolute"
                top={{ md: '160px', base: '80px' }}
                left={{ md: '-20px', base: '-10px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 15s linear infinite`}
                bg="gray.100"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="meta-logo"
                  src="/meta-circle-logo.svg"
                />
              </Box>
            </Box>

            <Box
              position="absolute"
              flexDirection={'column'}
              top={{ md: '236px', base: '118px' }}
              left={{ md: '236px', base: '118px' }}
              height={{ md: '92px', base: '46px' }}
              width={{ md: '92px', base: '46px' }}
              borderRadius={{ md: 12, base: 6 }}
              boxShadow={'2xl'}
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
              bg="#fff"
            >
              <Image
                src={'/adstart-circle-logo.svg'}
                alt={'/adstart-circle-logo.svg'}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
              />
              <Text
                display={'none'}
                fontSize={{ md: 18, base: 10 }}
                fontWeight={'bold'}
              >
                ChatGPT
              </Text>
            </Box>

            <Box
              id="middle-orbit"
              position="absolute"
              top={{ md: `${ORBIT_DIAMETER / 8}`, base: '35.25px' }}
              left={{ md: `${ORBIT_DIAMETER / 8}`, base: '35.25px' }}
              width={{ md: '423px', base: '211.5px' }}
              height={{ md: '423px', base: '211.5px' }}
              border="2px #fff dashed"
              borderRadius="100%"
              animation={`${spinRight} 20s linear infinite`}
            >
              <Box
                id="middle-orbit-cirlces"
                position="absolute"
                top={{ md: '25px', base: '12.5px' }}
                left={{ md: '34px', base: '17px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 20s linear infinite`}
                bg="gray.200"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="Instagram logo"
                  src="/instagram-circle-logo.svg"
                />
              </Box>

              <Box
                id="middle-orbit-cirlces"
                position="absolute"
                top={{ md: '20px', base: '10px' }}
                left={{ md: '324px', base: '162px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 20s linear infinite`}
                bg="gray.200"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="Google analytics logo"
                  src="/google-analytics-circle-logo.svg"
                />
              </Box>

              <Box
                id="middle-orbit-cirlces"
                position="absolute"
                top={{ md: '380px', base: '190px' }}
                left={{ md: '184px', base: '92px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                animation={`${spinLeft} 20s linear infinite`}
                bg="gray.200"
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="Google search console logo"
                  src="/google-search-console-circle-logo.svg"
                />
              </Box>
            </Box>

            <Box
              id="outer-orbit"
              position="absolute"
              top="0"
              left="0"
              height={{
                md: `${ORBIT_DIAMETER}px`,
                base: `${ORBIT_DIAMETER / 2}px`,
              }}
              width={{
                md: `${ORBIT_DIAMETER}px`,
                base: `${ORBIT_DIAMETER / 2}px`,
              }}
              border="2px #fff dashed"
              borderRadius="100%"
              animation={`${spinRight} 25s linear infinite`}
            >
              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '-26px', base: '-13px' }}
                left={{ md: '264px', base: '132px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="Nuvemshop logo"
                  src="/nuvemshop-circle-logo.svg"
                />
              </Box>

              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '150px', base: '75px' }}
                left={{ md: '510px', base: '255px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="magazord logo"
                  src="/magazord-circle-logo.svg"
                />
              </Box>

              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '450px', base: '225px' }}
                left={{ md: '450px', base: '225px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="VTEX logo"
                  src="/vtex-circle-logo.svg"
                />
              </Box>

              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '500px', base: '250px' }}
                left={{ md: '130px', base: '65px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="TRAY logo"
                  src="/tray-circle-logo.svg"
                />
              </Box>

              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '300px', base: '150px' }}
                left={{ md: '-26px', base: '-13px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="Shopify logo"
                  src="/shopify-circle-logo.svg"
                />
              </Box>

              <Box
                id="outer-orbit-cirlces"
                position="absolute"
                top={{ md: '50px', base: '25px' }}
                left={{ md: '30px', base: '15px' }}
                height={{ md: '64px', base: '32px' }}
                width={{ md: '64px', base: '32px' }}
                borderRadius="100%"
                bg="gray.200"
                animation={`${spinLeft} 25s linear infinite`}
              >
                <Image
                  height={{ md: '64px', base: '32px' }}
                  width={{ md: '64px', base: '32px' }}
                  alt="vnda logo"
                  src="/vnda-circle-logo.svg"
                />
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex alignItems={'flex-end'} h="fit" w="full" p={8}>
          <Carousel ref={emblaRef} className="h-xl:hidden w-full">
            <CarouselContent>
              {testimonials.map(({ quote, footer }) => (
                <CarouselItem key={footer} className="max-w-fit">
                  <div>
                    <blockquote className="space-y-2 rounded-md bg-white p-4">
                      <p className="text-lg">&ldquo;{quote}&rdquo;</p>

                      <footer className="text-sm">{footer}</footer>
                    </blockquote>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </Flex>
      </Flex>

      <Flex
        h="100%"
        w={{
          base: '100%',
          md: `${logoUrl === eplusLogo ? '100%' : '45%'}`,
        }}
        borderTopLeftRadius={24}
        borderBottomLeftRadius={24}
        bg="#fff"
        alignItems="center"
        justify="center"
      >
        <Flex
          w={{ base: '100%', md: `${logoUrl === eplusLogo ? '40%' : '70%'}` }}
          align="center"
          justify="center"
          flexDir="column"
        >
          <Stack spacing="4" w="70%">
            {/* <CompanyLogo /> */}
            <img alt="logo" src={logoUrl} />

            <Input
              name="email"
              label={t.emailInputLabel}
              placeholder={t.emailInputPlaceholder}
              type="email"
              onChange={(e) => {
                e.preventDefault();
                handleInput('email', e.target.value);
              }}
            />

            <Input
              name="password"
              label={t.passwordInputLabel}
              placeholder={t.passwordInputPlaceholder}
              type="password"
              onChange={(e) => {
                e.preventDefault();

                handleInput('password', e.target.value);
              }}
            />
            <Button
              w={'full'}
              colorScheme="brand"
              bgGradient={
                logoUrl === eplusLogo
                  ? `linear(to-tr, #52bf4b, #52bf4b)`
                  : `linear(to-tr, brand.blue, brand.purple)`
              }
              _hover={{ opacity: 0.9 }}
              fontWeight="bold"
              isLoading={loading}
              onClick={async () => {
                setIsLoading(true);
                const data = await signIn<'credentials'>('credentials', {
                  redirect: false,
                  ...values,
                });

                if (!data || data.error) {
                  setIsLoading(false);

                  toast({
                    status: 'error',
                    title: 'Ocorreu um erro ao realizar o login.',
                    description:
                      'Verifique se suas credenciais estão corretas.',
                    position: 'bottom-right',
                    isClosable: true,
                  });

                  return;
                }

                router.push('/app/onboarding');

                setIsLoading(false);
              }}
            >
              {t.signInButton}
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}

const testimonials = [
  {
    quote:
      'Com o Adstart, a Etraction passou de 12 para 18 clientes por gestor/analista. Queria primeiramente agradecer todo o esforço da equipe. ',
    footer: 'Leandro Dalponte - Fundador da E-traction',
  },
  // {
  //   quote:
  //     'Queria primeiramente agradecer todo o esforço da equipe. Está sendo um grande case para nós e para vocês. Durante a Black Friday, o Adstart nos ajudou no crescimento de 20% do nosso faturamento.',
  //   footer: 'William Theiss - Fundador Arm Fitness',
  // },
];
